import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 67 78 68 "
        />

        <path
          d="M 21.408 57.15 L 35.838 22.142 L 41.197 22.142 L 56.576 57.15 L 50.91 57.15 L 46.527 46.547 L 30.816 46.547 L 26.687 57.15 L 21.408 57.15 Z M 32.25 42.774 L 44.99 42.774 L 41.067 33.079 C 39.871 30.133 38.983 27.713 38.401 25.821 C 37.924 28.064 37.249 30.293 36.376 32.506 L 32.25 42.774 Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;

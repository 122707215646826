import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(11.000000, 5.000000)">
        <path
          d="M 21.408 57.15 L 35.838 22.142 L 41.197 22.142 L 56.576 57.15 L 50.91 57.15 L 46.527 46.547 L 30.816 46.547 L 26.687 57.15 L 21.408 57.15 Z M 32.25 42.774 L 44.99 42.774 L 41.067 33.079 C 39.871 30.133 38.983 27.713 38.401 25.821 C 37.924 28.064 37.249 30.293 36.376 32.506 L 32.25 42.774 Z"
          fill="currentColor"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 72
                  L 89, 73 z"
      />
    </g>
  </svg>
);
export default IconLoader;
